import React, { useState, useRef, useEffect } from "react";
import TopBar from "../other-components/TopBar";
import ToastDialog from "../dialogs/ToastDialog/toast";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL, AVATARS_ARR } from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import { getStorage, setStorage } from "../modals/Storage";
import general from "../css/General.module.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../../../node_modules/@splidejs/react-splide/dist/css/splide.min.css";


function Vip() {
    const navigate = useNavigate();
    const toastDialogRef = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [tabChoosed, setTabChoosed] = useState(1);
    const [vipLevel, setVIPLevel] = useState(Number(getStorage("accountLevel")));
    const [isInputValCorrect, setInValCorrect] = useState(false);
    const [pageConst, setConstants] = useState({
        pageTitle: "VIP",
        isLoadingShow: false,
        availBalance: 0,
        accountExp: 0,
        accountTotalExp: 0,
        levelupRewardAllowed: false,
        monthlyRewardAllowed: false,
        totalPayoutTime: 0,
        recordList: [],
    });

    const [vipBenefits, setVipBenefits] = useState({
        levelExp: 0,
        levelupReward: 0,
        monthlyReward: 0,
        levelCompleted: 0,
        rebateRate: 0,
    });

    const slides = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const topBarClickAction = (data) => {
        if (data == "multiBtn1") {
            navigate("/withdraw", { replace: false });
        } else if (data == "multiBtn2") {
            navigate("/withdrawrecords", { replace: false });
        }
    };

    const onTabChoosed = (tab) => {
        setTabChoosed(tab);
    }

    // toast component
    const onToastChange = (data, msg) => { };

    const showToast = (message, duration, delay, mode) => {
        toastDialogRef.current.openDialog(message, duration, delay, mode);
    };

    const setLoadingShow = (data) => {
        setShowLoading(data);
    };


    const updateRecordList = (data) => {
        let tempData = [];

        for (let i = 0; i < data.length; i++) {
            tempData.push(
                <div
                    key={i}
                    className="row-view sb-view pd-10-15 bg-white br-5 mg-t-15"
                >
                    <div className="row-view">
                        <img
                            className="h-w-32"
                            src={require("../icons/transaction_icon.png")}
                        />

                        <div className="col-view mg-l-20">
                            <span className="ft-sz-17">{data[i]["d_title"]}</span>
                            <span className="ft-sz-12 mg-t-5">{data[i]["d_timestamp"]}</span>
                        </div>
                    </div>
                    <span className="ft-sz-17 cl-green">
                        ₹{data[i]["d_amount"]}
                    </span>
                </div>
            );
        }

        if (data.length > 0) {
            setConstants((previousState) => {
                return { ...previousState, recordList: tempData };
            });
        }
    };


    function getVIPInfo() {
        const fecthApiData = async (url, formData) => {
            try {
                const res = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        route: "route-get-vip-records",
                    },
                    body: JSON.stringify(formData),
                });

                const data = await res.json();
                setLoadingShow(false);

                if (data.status_code == "success") {
                    let accountLevel = data.data[0]["account_level"];
                    let accountExp = data.data[0]["account_exp"];
                    let accountTotalExp = data.data[0]["account_total_exp"];
                    let payoutTime = data.data[0]["payout_time"];
                    let levelupRewardAllowed = data.data[0]["levelup_reward_allowed"];
                    let monthlyRewardAllowed = data.data[0]["monthly_reward_allowed"];
                    let vipBenefits = data.vipBenefits;

                    setStorage("accountLevel", accountLevel);
                    updateRecordList(data.records);

                    setConstants(previousState => {
                        return { ...previousState, accountExp: accountExp, accountTotalExp: accountTotalExp, totalPayoutTime: payoutTime, levelupRewardAllowed: levelupRewardAllowed, monthlyRewardAllowed: monthlyRewardAllowed }
                    });

                    setVipBenefits(previousState => {
                        return { ...previousState, levelExp: vipBenefits.total_level_exp, levelupReward: vipBenefits.levelup_reward, monthlyReward: vipBenefits.monthly_reward, rebateRate: vipBenefits.rebate_rate, levelCompleted: vipBenefits.level_completed }
                    });
                }
            } catch (error) {
                setLoadingShow(false);
            }
        };

        if (!pageConst.isDataLoaded) {
            setLoadingShow(true);
            const formData = {
                USER_ID: getStorage("uid"),
                VIP_LEVEL: vipLevel,
            };
            fecthApiData(API_ACCESS_URL, formData);
        }
    }

    const claimVIPRewards = (rewardType) => {
        const fecthApiData = async (url, formData) => {
            try {
                const res = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        route: "route-claim-vip-rewards",
                        AuthToken: getStorage("secret"),
                    },
                    body: JSON.stringify(formData),
                });

                const data = await res.json();
                setLoadingShow(false);

                if (data.status_code == "success") {
                    setStorage("balance", data.account_balance);

                    if (rewardType == "MONTHLY_REWARD") {
                        setConstants(previousState => {
                            return { ...previousState, monthlyRewardAllowed: false }
                        });
                        showToast("Monthly Reward Claimed!", 2500, 0, 1);
                    } else if (rewardType == "LEVELUP_REWARD") {
                        setConstants(previousState => {
                            return { ...previousState, levelupRewardAllowed: false }
                        });
                        showToast("Levelup Reward Claimed!", 2500, 0, 1);
                    }

                    getVIPInfo();
                }
            } catch (error) {
                setLoadingShow(false);
            }
        };

        if (!pageConst.isLoadingShow) {
            setLoadingShow(true);
            const formData = {
                USER_ID: getStorage("uid"),
                REWARD_TYPE: rewardType,
            };
            fecthApiData(API_ACCESS_URL, formData);
        }
    };

    useEffect(() => {
        if (getStorage("uid")) {
            getVIPInfo();
        }
    }, [vipLevel]);

    useEffect(() => {
        const handleResize = () => {
            window.dispatchEvent(new Event('resize'));
        };

        const timer = setTimeout(handleResize, 200);

        // Cleanup if necessary
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className={[general.viewCenter, general.appBackground].join(" ")}>
            <div
                className={[
                    general.height100vh,
                    general.respWidth,
                    general.overflowScrollY,
                    general.appContentsBackground,
                    general.hideScrollBar,
                ].join(" ")}
            >
                <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
                <LoadingDialog intentData={showLoading} />

                <div className="zoomAnimView">
                    <TopBar
                        intentData={pageConst}
                        multiBtn={false}
                        multiBtn1=""
                        multiBtn2=""
                        color={1}
                        background={1}
                        updateState={topBarClickAction}
                    />

                    <div className="col-view">
                        <div
                            className={[general.width100, general.rowView, general.pd70px15px, general.bgCustomColor1].join(" ")}
                        >
                            <div className="row-view">
                                <div
                                    className="h-w-70 ovf-hidden br-50"
                                >
                                    {getStorage("avatarId") ? (
                                        <img
                                            src={
                                                AVATARS_ARR[Number(getStorage("avatarId")) - 1]["avatarURL"]
                                            }
                                            className="h-w-70"
                                            alt="icon"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col-view mg-l-15">
                                    <img
                                        src={require(`../media/icons/vip_${getStorage("accountLevel")}_bedge.png`)}
                                        className="w-65-p"
                                        alt="icon"
                                    />
                                    <p className="cl-white mg-t-5">{getStorage("uname")}</p>
                                </div>
                            </div>
                        </div>

                        <div className="row-view pd-15 mg-t--60">
                            <div className="col-view a-center w-50 pd-20 br-10 bg-white">
                                <p className="cl-drk-red ft-sz-18">{pageConst.accountTotalExp} EXP</p>
                                <p className="cl-l-black ft-sz-13">My Experience</p>
                            </div>

                            <div className="col-view a-center w-50 pd-20 br-10 bg-white mg-l-15">
                                <p className="ft-sz-18">{pageConst.totalPayoutTime} Days</p>
                                <p className="cl-l-black ft-sz-13">Payout Time</p>
                            </div>
                        </div>


                        <div className={[general.pd10px15px]}>
                            <div className="ft-sz-12 pd-0-10 cl-grey br-a-l-grey br-5 mg-b-20">
                                VIP level rewards are settled at 2am on the 1st of every month
                            </div>

                            <Splide
                                options={{
                                    type: 'loop',
                                    padding: '0.5rem',
                                    rewind: false,
                                    arrows: false,
                                    pagination: false,
                                    start: Number(localStorage.getItem("accountLevel") || 1) - 1,
                                }}
                                onMoved={(splide, newIndex) => {
                                    setVIPLevel(newIndex + 1)
                                }}
                            >
                                {slides.map((slide, index) => (
                                    <SplideSlide key={index}>
                                        <div
                                            className={`vipSlideShowItem vipSlideShowBedge${slide}Item`}
                                        >
                                            <div className="vipSlideShowBedgeItemInfo">
                                                <div className="v-center">
                                                    <img
                                                        src={require("../media/icons/bedge_1_crown.png")}
                                                        alt="icon"
                                                        className="h-w-32"
                                                    />
                                                    <p className="cl-white ft-sz-25 mg-l-5">VIP{slide}</p>
                                                    <p className="cl-white ft-sz-14 mg-l-10">{Number(getStorage("accountLevel")) >= slide ? 'Achieved' : 'Not open yet'}</p>
                                                </div>

                                                <p className="bedgeLineInfo">Dear VIP{slide} customer</p>
                                            </div>
                                            <img
                                                src={require(`../media/icons/vip_bedge_${slide}_icon.png`)}
                                                className="vipSlideShowBedgeItemIcon"
                                                alt="icon"
                                            />

                                            {Number(getStorage("accountLevel")) > slide ?
                                                <div className="mg-t-15">
                                                    <p className="ft-sz-16 mg-t-10 cl-white">Received VIP level up bonus!</p>
                                                </div>
                                                :
                                                <div className="mg-t-15">
                                                    {Number(getStorage("accountLevel")) >= slide ?
                                                        <p className="ft-sz-13 cl-white">Level maintenance</p>
                                                        :
                                                        <p className="w-fit-content pd-0-10 cl-white ft-sz-13 br-5 br-a-l-white">Bet ₹1 = 1EXP</p>
                                                    }


                                                    {Number(getStorage("accountLevel")) >= slide ?
                                                        <div className="progressContainer">
                                                            <p className="pointsContainer vipProgressBarBg">{pageConst.accountExp}/{vipBenefits.levelExp}</p>
                                                            <p>{vipBenefits.levelCompleted}% completed</p>
                                                        </div>
                                                        :
                                                        <div className="progressContainer">
                                                            <p></p>
                                                            <p>VIP{slide}</p>
                                                        </div>
                                                    }

                                                    <div className={`vipProgressBarBedge${slide} vipProgressBarBg mg-t-5`}>
                                                        <div className="vipProgressBarPercent" style={{ width: Number(getStorage("accountLevel")) >= slide ? `${vipBenefits.levelCompleted}%` : '0%' }}></div>
                                                    </div>

                                                    {Number(getStorage("accountLevel")) < slide ?
                                                        <div className="progressContainer">
                                                            <p className="pointsContainer vipProgressBarBg">{Number(getStorage("accountLevel")) >= slide ? pageConst.accountExp : 0}/{vipBenefits.levelExp}</p>
                                                            <p>{vipBenefits.levelExp} EXP can be leveled up</p>
                                                        </div>
                                                        :
                                                        <p className="ft-sz-13 mg-t-10 cl-white">Incomplete will be deducted by the system</p>
                                                    }
                                                </div>}

                                        </div>
                                    </SplideSlide>
                                ))}
                            </Splide>

                            <div className="mg-t-20 pd-10 br-5 bg-white">
                                <p>VIP Level Benefits</p>

                                <div className="line-hv-grey mg-t-10"></div>

                                <div className="row-view mg-t-15">
                                    <img
                                        src={require("../media/icons/vip_gifts_icon.png")}
                                        className="w-50-p"
                                        alt="icon"
                                    />

                                    <div className="col-view flex mg-l-10">
                                        <p>Level up rewards</p>
                                        <p className="ft-sz-12">Each account can only receive 1 time</p>
                                    </div>

                                    <div className="col-view">
                                        <div className="row-view jus-content-flx-center pd-0-15 br-5 br-a-l-grey">
                                            <img
                                                src={require("../media/icons/vip_wallet_icon.png")}
                                                className="w-20-p"
                                                alt="icon"
                                            />
                                            <p className="ft-sz-16 mg-l-5">{vipBenefits.levelupReward}</p>
                                        </div>

                                        <div className="row-view jus-content-flx-center pd-0-15 br-5 br-a-l-grey mg-t-5">
                                            <img
                                                src={require("../media/icons/vip_love_icon.png")}
                                                className="w-20-p"
                                                alt="icon"
                                            />
                                            <p className="ft-sz-16 mg-l-5">0</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="row-view mg-t-20">
                                    <img
                                        src={require("../media/icons/vip_star_icon.png")}
                                        className="w-50-p"
                                        alt="icon"
                                    />

                                    <div className="col-view flex mg-l-10">
                                        <p>Monthly reward</p>
                                        <p className="ft-sz-12">Each account can only receive 1 time per month</p>
                                    </div>

                                    <div className="col-view">
                                        <div className="row-view jus-content-flx-center pd-0-15 br-5 br-a-l-grey">
                                            <img
                                                src={require("../media/icons/vip_wallet_icon.png")}
                                                className="w-20-p"
                                                alt="icon"
                                            />
                                            <p className="ft-sz-16 mg-l-5">{vipBenefits.monthlyReward}</p>
                                        </div>

                                        <div className="row-view jus-content-flx-center pd-0-15 br-5 br-a-l-grey mg-t-5">
                                            <img
                                                src={require("../media/icons/vip_love_icon.png")}
                                                className="w-20-p"
                                                alt="icon"
                                            />
                                            <p className="ft-sz-16 mg-l-5">0</p>
                                        </div>
                                    </div>

                                </div>

                                {vipBenefits.rebateRate > 0 ?
                                    <div className="row-view mg-t-20">
                                        <img
                                            src={require("../media/icons/vip_coins_icon.png")}
                                            className="w-50-p"
                                            alt="icon"
                                        />

                                        <div className="col-view flex mg-l-10">
                                            <p>Rebate rate</p>
                                            <p className="ft-sz-12">Increase income of rebate</p>
                                        </div>

                                        <div className="col-view">
                                            <div className="row-view jus-content-flx-center pd-0-15 br-5 br-a-l-grey">
                                                <img
                                                    src={require("../media/icons/vip_stack_icon.png")}
                                                    className="w-20-p"
                                                    alt="icon"
                                                />
                                                <p className="ft-sz-16 mg-l-5">{vipBenefits.rebateRate}%</p>
                                            </div>
                                        </div>

                                    </div>
                                    : null}
                            </div>

                            <div className="mg-t-20 pd-10 br-5 bg-white">
                                <p>My Benefits</p>

                                <div className="line-hv-grey mg-t-10"></div>

                                <div className="g-v-2 mg-t-10">
                                    <div className="col-view br-10 bg-l-black-2">
                                        <div className="ovf-hidden br-t-rl-10 bg-grad-1">
                                            <img
                                                src={require("../media/icons/vip_welfare_1_icon.png")}
                                                className="w-100"
                                                alt="icon"
                                            />

                                            <div className="w-100 row-view ft-sz-14 pd-0-10 cl-white bg-l-black-1">
                                                <img
                                                    src={require("../media/icons/vip_wallet_icon.png")}
                                                    className="w-15-p"
                                                    alt="icon"
                                                />
                                                <p className="mg-l-5">{vipBenefits.levelupReward}</p>
                                            </div>
                                        </div>

                                        <div className="col-view flex pd-10">
                                            <p>Level up rewards</p>
                                            <p className="ft-sz-12">Each account can only receive 1 time</p>

                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.width100,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.mgT10px,
                                                    general.borderRadius30px,
                                                    pageConst.levelupRewardAllowed ? general.bgCustomColor2 : general.bgDisable,
                                                ].join(" ")}
                                                onClick={() => pageConst.levelupRewardAllowed ? claimVIPRewards("LEVELUP_REWARD") : null}
                                            >
                                                <span className="cl-white">Receive</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-view br-10 bg-l-black-2">
                                        <div className="ovf-hidden br-t-rl-10 bg-grad-1">
                                            <img
                                                src={require("../media/icons/vip_welfare_2_icon.png")}
                                                className="w-100"
                                                alt="icon"
                                            />

                                            <div className="w-100 row-view ft-sz-14 pd-0-10 cl-white bg-l-black-1">
                                                <img
                                                    src={require("../media/icons/vip_wallet_icon.png")}
                                                    className="w-15-p"
                                                    alt="icon"
                                                />
                                                <p className="mg-l-5">{vipBenefits.monthlyReward}</p>
                                            </div>
                                        </div>

                                        <div className="col-view flex pd-10">
                                            <p>Monthly reward</p>
                                            <p className="ft-sz-12">Each account can only receive 1 time per month</p>

                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.width100,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.mgT10px,
                                                    general.borderRadius30px,
                                                    pageConst.monthlyRewardAllowed ? general.bgCustomColor2 : general.bgDisable,
                                                ].join(" ")}
                                                onClick={() => pageConst.monthlyRewardAllowed ? claimVIPRewards("MONTHLY_REWARD") : null}
                                            >
                                                <span className="cl-white">Receive</span>
                                            </div>
                                        </div>
                                    </div>

                                    {vipBenefits.rebateRate > 0 ?
                                        <div className="col-view br-10 bg-l-black-2">
                                            <div className="ovf-hidden br-t-rl-10 bg-grad-1">
                                                <img
                                                    src={require("../media/icons/vip_welfare_3_icon.png")}
                                                    className="w-100"
                                                    alt="icon"
                                                />

                                                <div className="w-100 row-view ft-sz-14 pd-0-10 cl-white bg-l-black-1">
                                                    <img
                                                        src={require("../media/icons/vip_wallet_icon.png")}
                                                        className="w-15-p"
                                                        alt="icon"
                                                    />
                                                    <p className="mg-l-5">0.1%</p>
                                                </div>
                                            </div>

                                            <div className="col-view flex pd-10">
                                                <p>Rebate rate</p>
                                                <p className="ft-sz-12">Increase income of rebate</p>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>

                            <div className="mg-t-20 pd-10 br-5 bg-white">
                                <div className="row-view">
                                    <div className={`w-50 v-center pd-10 ${tabChoosed == 1 ? 'br-btm-theme-color' : ''}`} onClick={() => onTabChoosed(1)}>History</div>
                                    <div className={`w-50 v-center pd-10 ${tabChoosed == 2 ? 'br-btm-theme-color' : ''}`} onClick={() => onTabChoosed(2)}>Rules</div>
                                </div>

                                {tabChoosed == 1 ?
                                    <div className="col-view min-h-300">{pageConst.recordList.length > 0 ? pageConst.recordList : <div className="w-100 v-center mg-t-30">No records available</div>}</div>
                                    :
                                    <div className="col-view v-center jus-content-flx-start pd-10 min-h-300">
                                        <p className="ft-sz-20 cl-drk-red mg-t-20">VIP privileges</p>
                                        <p>VIP rule description</p>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Upgrade standard
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">The IP member's experience points (valid bet amount) that meet the requirements of the corresponding rank will be promoted to the corresponding VIP level, the member's VIP data statistics period starts from 00:00:00 days VIP system launched.VIP level calculation is refreshed every 10 minutes! The corresponding experience level is calculated according to valid odds 1:1 !</p>
                                        </div>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Upgrade order
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">The VIP level that meets the corresponding requirements can be promoted by one level every day, but the VIP level cannot be promoted by leapfrogging.</p>
                                        </div>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Level maintenance
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">VIP members need to complete the maintenance requirements of the corresponding level within 30 days after the "VIP level change"; if the promotion is completed during this period, the maintenance requirements will be calculated according to the current level.</p>
                                        </div>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Downgrade standard
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">If a VIP member fails to complete the corresponding level maintenance requirements within 30 days, the system will automatically deduct the experience points corresponding to the level. If the experience points are insufficient, the level will be downgraded, and the corresponding discounts will be adjusted to the downgraded level accordingly.</p>
                                        </div>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Upgrade Bonus
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">The upgrade benefits can be claimed on the VIP page after the member reaches the VIP membership level, and each VIP member can only get the upgrade reward of each level once.</p>
                                        </div>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Monthly reward
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">VIP members can earn the highest level of VIP rewards once a month.Can only be received once a month. Prizes cannot be accumulated. And any unclaimed rewards will be refreshed on the next settlement day. When receiving the highest level of monthly rewards this month Monthly Rewards earned in this month will be deducted e.g. when VIP1 earns 500 and upgrades to VIP2 to receive monthly rewards 500 will be deducted.</p>
                                        </div>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Real-time rebate
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">The higher the VIP level, the higher the return rate, all the games are calculated in real time and can be self-rewarded!</p>
                                        </div>

                                        <div className="pd-10 col-view v-center br-10 br-a-l-black mg-t-30">
                                            <div
                                                className={[
                                                    general.height30px,
                                                    general.viewCenter,
                                                    general.colorWhite,
                                                    general.fontS16Px,
                                                    general.pd5px15px,
                                                    general.borderRadius30px,
                                                    general.bgCustomColor2,
                                                ].join(" ")}
                                            >
                                                Safe
                                            </div>
                                            <p className="ft-sz-14 mg-t-5">VIP members who have reached the corresponding level will get additional benefits on safe deposit based on the member's VIP level.</p>
                                        </div>


                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Vip;